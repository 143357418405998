import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import  zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/css/global.less'
import '@/styles/common.less'
import '@/styles/element-ui-reset.less'


let app = createApp(App)
for (const name in ElementPlusIconsVue){
    app.component(name, ElementPlusIconsVue[name])
}
app.use(ElementPlus, { locale: zhCn })
app.use(store).use(router).mount('#app')
