import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    redirect: "/loginPage",
  },
  {
    path: "/loginPage",
    name:'LoginPage',
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "ProjectAdmin",
        name: "ProjectAdmin",
        component: () => import("../views/ProjectAdmin/ProjectAdmin.vue"),
      },
      {
        path: "equitmentAdmin",
        name: "equitmentAdmin",
        component: () => import("../views/equitmentAdmin/equitmentAdmin.vue"),
      },
      {
        path: "cardAdmin",
        name: "cardAdmin",
        component: () => import("../views/cardAdmin/cardAdmin.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
