<template>
  <div class="home">
    <div class="header">
      <div class="nav">
        <div
          v-for="item in menuList"
          :key="item.name"
          :class="['nav-list', { active: url == item.path }]"
          @click="toPage(item.path)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="content">
      <div class="logo-box">
        <div class="mune-name">
          <img src="@/assets/images/tzsklogo.png" alt="" srcset="" />
        </div>
        <div class="legout">
          <span @click="logout">退出登录</span>
          <el-icon><Avatar /></el-icon>
          设备管理员
        </div>
      </div>
      <div class="section">
        <div>
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>   

<script>
export default {
  name: "HomeView",
  data() {
    return {
      menuList: [
        { name: "合作方管理", path: "/home/ProjectAdmin" },
        { name: "网关设备管理", path: "/home/equitmentAdmin" },
        { name: "物联网卡管理", path: "/home/cardAdmin" },
      ],
    };
  },
  mounted() {},
  computed: {
    url() {
      return this.$route.path;
    },
    userName() {
      return JSON.parse(localStorage.getItem("userInfo"))
        ? JSON.parse(localStorage.getItem("userInfo")).user_name
        : "";
    },
  },
  methods: {
    toPage(path) {
      this.url = path;
      this.$router.push(path);
    },
    logout() {
      this.$confirm("确定退出登录吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("token");
          this.$router.push("/loginPage");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  height: 100%;
  display: flex;
  .header {
    height: 100%;
    width: 240px;
    .nav {
      height: 100%;
      background: linear-gradient(#0ec69a, #00c06c);
      padding-top: 20px;
      box-sizing: border-box;
      .nav-list {
        height: 48px;
        line-height: 48px;
        display: flex;
        align-items: center;
        justify-content: left;
        color: #ffffff;
        font-size: 15px;
        text-align: center;
        cursor: pointer;
        width: 94%;
        padding-left: 10%;
        box-sizing: border-box;
        border-radius: 0 10px 10px 0;
        > img {
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }
      }
      .active {
        background: #eff7f6;
        color: #0ec69a;
      }
    }
    > img {
      height: 65%;
      display: none;
    }

    .user-message {
      cursor: pointer;
      margin-left: 20px;
    }
  }
  .content {
    width: calc(100% - 240px);
    background: #eff4f9;
    .logo-box {
      height: 70px;
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #ffffff;
      margin-bottom: 10px;
      .mune-name {
        font-size: 22px;
        color: #4a5975;
        display: flex;
        align-items: center;
        > img {
          height: 50px;
        }
      }
      .legout {
        font-size: 16px;
        color: #4a5975;
        display: flex;
        align-items: center;
        > span{
          margin-right: 10px;
          cursor: pointer;
        }
        i {
          font-size: 24px;
          margin-right: 3px;
        }
      }
    }
    .section {
      height: calc(100% - 82px);
      padding: 10px;
      padding-top: 0;
      box-sizing: border-box;
      > div {
        height: 100%;
      }
    }
  }
}
</style>
